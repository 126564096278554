import localizeFilter from '@/filter/localize.filter'
import Journal from '@/config/Journal.dashboard'
export default function insertFilial(filial){
    if(filial != ''){
        this.$Progress.start()
        axios.get('/dashboard.php', 
            {
                params: 
                {
                    type: 25,
                    value: filial
                }
            })
            .then(response => {
                if(response.data != -10){
                    Journal(localStorage.getItem('login'), 'Добавлен новый филиал: ' + filial)
                    this.filials.push({
                        value: response.data,
                        text: filial
                    })
                    this.newFilial = ''
                    UIkit.modal("#modal-insert").hide()
                    UIkit.notification({message: localizeFilter('Insert'), pos:'bottom-center'})
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
    }
}